import { Route, Routes, useLocation } from 'react-router-dom';
import {
  MyOrder,
  MyOrders,
  OurNotFound,
  useCommonRoutes,
  useGeneralRoutes,
  useLoginLogoutRoutes,
  usePclRoutes,
} from '@petconsole/pure-fe-feature';
import { useMyShopRoutes } from '@petconsole/pure-fe-my-shops';
import { OurEnterSite, OurExitSite, OurExternalPath, useOurRoutes } from '@petconsole/pure-fe-mui';
import { webPath } from '@petconsole/pure-shared';
import { Business, Businesses } from '../business';
import { DogPark, DogParks } from '../dogPark';
import { Features } from '../features';
import { Groomer, Groomers } from '../groomer';
import { LandingPage } from '../landing';
import { FoundPet, FoundPets, LostPet, LostPets } from '../lostFoundPet';
import { AboutUs, Faq, Pricing, Welcome } from '../misc';
import { MyPets, Pet, Pets } from '../pet';
import { PetFood, PetFoods } from '../petFood';
import { PetTrainer, PetTrainers } from '../petTrainer';
import { PetWalker, PetWalkers } from '../petWalker';
import { PetWorkplace, PetWorkplaces } from '../petWorkplace';
import { Place, Places } from '../place';
import { Sitter, Sitters } from '../sitter';
import { Vet, Vets } from '../vet';

const AppRoutes = () => {
  const { pathname } = useLocation();
  const { authRoute, routeTo } = useOurRoutes();
  const commonRoutes = useCommonRoutes();
  const generalRoutes = useGeneralRoutes();
  const loginLogoutRoutes = useLoginLogoutRoutes();
  const myShopRoutes = useMyShopRoutes();
  const pclRoutes = usePclRoutes();

  return (
    <Routes>
      <>{commonRoutes.map((commonRoute) => commonRoute)}</>
      <>{generalRoutes.map((generalRoute) => generalRoute)}</>
      <>{loginLogoutRoutes.map((loginLogoutRoute) => loginLogoutRoute)}</>
      <>{pclRoutes.map((pclRoute) => pclRoute)}</>
      {routeTo(webPath.home, LandingPage)}
      {routeTo(webPath.about, AboutUs)}
      {routeTo(webPath.dogParks, DogParks)}
      {routeTo(`${webPath.dogParks}/:id`, DogPark)}
      {routeTo(webPath.faq, Faq)}
      {routeTo(webPath.features, Features)}
      {routeTo(webPath.foundPets, FoundPets)}
      {routeTo(`${webPath.foundPets}/:id`, FoundPet)}
      {routeTo(webPath.groomers, Groomers)}
      {routeTo(`${webPath.groomers}/:id`, Groomer)}
      {routeTo(webPath.lostPets, LostPets)}
      {routeTo(`${webPath.lostPets}/:id`, LostPet)}
      {routeTo(webPath.petFoods, PetFoods)}
      {routeTo(`${webPath.petFoods}/:id`, PetFood)}
      {routeTo(webPath.petTrainers, PetTrainers)}
      {routeTo(`${webPath.petTrainers}/:id`, PetTrainer)}
      {routeTo(webPath.petWalkers, PetWalkers)}
      {routeTo(`${webPath.petWalkers}/:id`, PetWalker)}
      {routeTo(webPath.pricing, Pricing)}
      {routeTo(webPath.sitters, Sitters)}
      {routeTo(`${webPath.sitters}/:id`, Sitter)}
      {routeTo(webPath.vets, Vets)}
      {routeTo(`${webPath.vets}/:id`, Vet)}
      {authRoute(webPath.businesses, Businesses)}
      {authRoute(`${webPath.businesses}/:id`, Business)}
      {authRoute(webPath.myOrders, MyOrders)}
      {authRoute(`${webPath.myOrders}/:id`, MyOrder)}
      {authRoute(webPath.pets, Pets)}
      {authRoute(`${webPath.pets}/:id`, Pet)}
      {authRoute(webPath.myPets, MyPets)}
      {authRoute(`${webPath.myPets}/:id`, Pet)}
      {authRoute(webPath.places, Places)}
      {authRoute(`${webPath.places}/:id`, Place)}
      <>{myShopRoutes.map((myShopRoute) => myShopRoute)}</>
      {authRoute(webPath.welcome, Welcome)}
      {authRoute(webPath.petWorkplaces, PetWorkplaces)}
      {authRoute(`${webPath.petWorkplaces}/:id`, PetWorkplace)}
      <Route path="/stripe-onboard/*" element={<OurExternalPath path={pathname.replace('/stripe-onboard/', '')} />} />
      {routeTo(`${webPath.site}/:site`, OurExitSite)}
      {routeTo(`${webPath.changeSite}/:id`, OurEnterSite)}
      {routeTo('*', OurNotFound)}
    </Routes>
  );
};

// Disabled this due to re-rending issue in useLocation: see https://github.com/remix-run/react-router/issues/10339
// AppRoutes.whyDidYouRender = true;

export default AppRoutes;
