import { LazyLoad } from '@petconsole/pure-fe-mui';

const AboutUs = LazyLoad(() => import('./AboutUs'));
const Faq = LazyLoad(() => import('./Faq'));
const Pricing = LazyLoad(() => import('./Pricing'));
const Welcome = LazyLoad(() => import('./Welcome'));

export { AboutUs };
export { Faq };
export { Pricing };
export { Welcome };
