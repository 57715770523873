import { Campaign, HealthAndSafety, Hearing, Pets, Place, Storefront, Work } from '@mui/icons-material';
import { Divider } from '@mui/material';
import {
  OurDogIcon,
  OurDogTrainerIcon,
  OurDogWalkerIcon,
  OurGroomerIcon,
  OurHomeHeartIcon,
  OurPetBowlIcon,
} from '@petconsole/pure-fe-icon';
import {
  AppMenuItems,
  OurCommunityMenu,
  OurGeneralMenu,
  OurHomeMenuItem,
  OurListingsMenu,
  OurLogoutMenuItem,
  OurMarketplaceMenu,
  OurMyMenu,
  OurSitesMenu,
} from '@petconsole/pure-fe-mui';
import { webPath } from '@petconsole/pure-shared';
import FeaturesMenuItem from './FeaturesMenuItem';

const MemberMenuItems = () => {
  const listingItems = [
    [Storefront, 'Businesses', webPath.businesses, 'Pet Related'],
    [OurDogIcon, 'Dog Parks', webPath.dogParks],
    [OurGroomerIcon, 'Groomers', webPath.groomers],
    [Campaign, 'Lost Pets', webPath.lostPets],
    [Hearing, 'Found Pets', webPath.foundPets],
    [OurPetBowlIcon, 'Pet Food', webPath.petFoods],
    [Place, 'Places', webPath.places, 'Pet Friendly'],
    [OurHomeHeartIcon, 'Sitters', webPath.sitters],
    [OurDogTrainerIcon, 'Trainers', webPath.petTrainers],
    [HealthAndSafety, 'Vets', webPath.vets],
    [OurDogWalkerIcon, 'Walkers', webPath.petWalkers],
    [Work, 'Workplaces', webPath.petWorkplaces, 'Pet Friendly'],
  ] as AppMenuItems;

  return (
    <>
      <OurHomeMenuItem />
      <OurCommunityMenu add={[[Pets, 'Pets', webPath.pets]]} />
      <OurMarketplaceMenu />
      <Divider />
      <FeaturesMenuItem />
      <OurListingsMenu add={listingItems} />
      <OurMyMenu add={[[Pets, 'Pets', webPath.myPets]]} />
      <OurGeneralMenu />
      <OurSitesMenu exclude={[webPath.petConsole]} />
      <Divider />
      <OurLogoutMenuItem />
    </>
  );
};

MemberMenuItems.whyDidYouRender = true;

export default MemberMenuItems;
