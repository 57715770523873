import { List } from '@mui/material';
import { OurFeaturesIcon } from '@petconsole/pure-fe-icon';
import { OurAppMenuItem } from '@petconsole/pure-fe-mui';
import { webPath } from '@petconsole/pure-shared';

const FeaturesMenuItem = () => {
  return (
    <List disablePadding>
      <OurAppMenuItem item={[OurFeaturesIcon, 'Features', webPath.features]} />
    </List>
  );
};

FeaturesMenuItem.whyDidYouRender = true;

export default FeaturesMenuItem;
