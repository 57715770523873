import * as React from 'react';
import type { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgOurDogTrainerIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M19.6 19.902c-.022-.1-.09-.109-.167-.11-.04-.001-.081.01-.122.013-.438.017-.875.049-1.312-.024-.261-.043-.319-.13-.396-.37-.068-.212-.066-.426-.098-.64-.106-.705-.469-1.287-.881-1.846-.285-.386-.632-.72-.916-1.107-.538-.733-.972-1.536-1.514-2.265-.158-.211-.201-.392-.142-.643.101-.426-.011-.846-.242-1.06-.11.245-.152.52-.35.784-.004-.305 0-.55-.109-.78-.048.194-.08.388-.106.582-.034.265-.078.29-.355.288-.124 0-.26.01-.34.17a.56.56 0 0 1-.584.323c-.098-.01-.167.056-.138.126.083.2.034.407.067.607.038.228.218.332.415.41.118.048.24.061.318-.049.112-.158.232-.096.356-.04.14.063.273.13.268.32a5 5 0 0 1-.115.831c-.116.566-.104 1.101.289 1.57a.3.3 0 0 1 .068.188q.022 1.11.054 2.218c.002.098-.031.157-.126.18-.066.015-.133.027-.198.046-.117.033-.17.118-.096.214.122.16.303.08.46.07.115-.008.18-.079.181-.206a.9.9 0 0 1 .096-.41c.004.202.049.367-.006.525-.053.153-.22.106-.333.158-.059.027-.077.072-.08.13-.004.06.018.117.078.128.19.037.382.038.57-.011.06-.016.087-.083.095-.148a10 10 0 0 0 .054-.803c.01-.456.035-.912.055-1.418.175.145.32.263.462.384.063.053.132.115.123.204-.035.344.178.536.416.725.16.127.333.244.454.429h-1a.211.211 0 0 0 0 .422h.072a.15.15 0 0 0-.033.093.15.15 0 0 0 .15.15h1.992a.15.15 0 0 0 .057-.289c-.094-.11-.014-.149.08-.195q.12-.06.243-.105c.041-.015.09-.043.125.019.215.371.585.443.963.49.373.044.738-.003 1.09-.133.057-.022.129-.044.108-.145" />
    <path d="M13.288 11.38c.17-.915-1.042-1.012-1.042-1.012-1.23-.015-2.05-.587-2.2-1.75-.105-.819-.315-1.558-.843-2.168l.101.002a2.195 2.195 0 1 0-1.128-.312c-.957-.18-1.72.304-1.955 1.305a132 132 0 0 0-.852 3.886c-.108.527-.121 1.076.295 1.504.236.242.217.52.217.81-.001 1.938-.008 3.877.003 5.815.005.856.431 1.356 1.084 1.33.624-.026 1.007-.521 1.009-1.333.005-1.745.017-3.49-.004-5.234-.007-.482.032-.872.509-1.143.332-.189.408-.581.496-.935.11-.439.12-.904.35-1.351.572.553 1.253.857 2 .978.689.112 1.475.596 1.96-.392" />
    <path d="M19.6 19.902c-.022-.1-.09-.109-.167-.11-.04-.001-.081.01-.122.013-.438.017-.875.049-1.312-.024-.261-.043-.319-.13-.396-.37-.068-.212-.066-.426-.098-.64-.106-.705-.469-1.287-.881-1.846-.285-.386-.632-.72-.916-1.107-.538-.733-.972-1.536-1.514-2.265-.158-.211-.201-.392-.142-.643.101-.426-.011-.846-.242-1.06-.11.245-.152.52-.35.784-.004-.305 0-.55-.109-.78-.048.194-.08.388-.106.582-.034.265-.078.29-.355.288-.124 0-.26.01-.34.17a.56.56 0 0 1-.584.323c-.098-.01-.167.056-.138.126.083.2.034.407.067.607.038.228.218.332.415.41.118.048.24.061.318-.049.112-.158.232-.096.356-.04.14.063.273.13.268.32a5 5 0 0 1-.115.831c-.116.566-.104 1.101.289 1.57a.3.3 0 0 1 .068.188q.022 1.11.054 2.218c.002.098-.031.157-.126.18-.066.015-.133.027-.198.046-.117.033-.17.118-.096.214.122.16.303.08.46.07.115-.008.18-.079.181-.206a.9.9 0 0 1 .096-.41c.004.202.049.367-.006.525-.053.153-.22.106-.333.158-.059.027-.077.072-.08.13-.004.06.018.117.078.128.19.037.382.038.57-.011.06-.016.087-.083.095-.148a10 10 0 0 0 .054-.803c.01-.456.035-.912.055-1.418.175.145.32.263.462.384.063.053.132.115.123.204-.035.344.178.536.416.725.16.127.333.244.454.429h-1a.211.211 0 0 0 0 .422h.072a.15.15 0 0 0-.033.093.15.15 0 0 0 .15.15h1.992a.15.15 0 0 0 .057-.289c-.094-.11-.014-.149.08-.195q.12-.06.243-.105c.041-.015.09-.043.125.019.215.371.585.443.963.49.373.044.738-.003 1.09-.133.057-.022.129-.044.108-.145" />
    <path d="M13.288 11.38c.17-.915-1.042-1.012-1.042-1.012-1.23-.015-2.05-.587-2.2-1.75-.105-.819-.315-1.558-.843-2.168l.101.002a2.195 2.195 0 1 0-1.128-.312c-.957-.18-1.72.304-1.955 1.305a132 132 0 0 0-.852 3.886c-.108.527-.121 1.076.295 1.504.236.242.217.52.217.81-.001 1.938-.008 3.877.003 5.815.005.856.431 1.356 1.084 1.33.624-.026 1.007-.521 1.009-1.333.005-1.745.017-3.49-.004-5.234-.007-.482.032-.872.509-1.143.332-.189.408-.581.496-.935.11-.439.12-.904.35-1.351.572.553 1.253.857 2 .978.689.112 1.475.596 1.96-.392" />
  </svg>
);
export default SvgOurDogTrainerIcon;
