import { Campaign, HealthAndSafety, Hearing } from '@mui/icons-material';
import { Divider } from '@mui/material';
import {
  OurDogIcon,
  OurDogTrainerIcon,
  OurDogWalkerIcon,
  OurGroomerIcon,
  OurHomeHeartIcon,
  OurPetBowlIcon,
} from '@petconsole/pure-fe-icon';
import {
  AppMenuItems,
  OurBlogMenuItem,
  OurGeneralMenu,
  OurHomeMenuItem,
  OurListingsMenu,
  OurLoginJoinMenuItems,
  OurSitesMenu,
} from '@petconsole/pure-fe-mui';
import { webPath } from '@petconsole/pure-shared';
import FeaturesMenuItem from './FeaturesMenuItem';

const GuestMenuItems = () => {
  const listingItems = [
    [OurDogIcon, 'Dog Parks', webPath.dogParks],
    [OurGroomerIcon, 'Groomers', webPath.groomers],
    [Campaign, 'Lost Pets', webPath.lostPets],
    [Hearing, 'Found Pets', webPath.foundPets],
    [OurPetBowlIcon, 'Pet Food', webPath.petFoods],
    [OurHomeHeartIcon, 'Sitters', webPath.sitters],
    [OurDogTrainerIcon, 'Trainers', webPath.petTrainers],
    [HealthAndSafety, 'Vets', webPath.vets],
    [OurDogWalkerIcon, 'Walkers', webPath.petWalkers],
  ] as AppMenuItems;

  return (
    <>
      <OurHomeMenuItem />
      <Divider />
      <OurBlogMenuItem />
      <Divider />
      <FeaturesMenuItem />
      <OurListingsMenu guest add={listingItems} />
      <OurGeneralMenu guest exclude={[webPath.credits, webPath.welcome]} />
      <OurSitesMenu guest exclude={[webPath.petConsole]} />
      <Divider />
      <OurLoginJoinMenuItems />
    </>
  );
};

GuestMenuItems.whyDidYouRender = true;

export default GuestMenuItems;
