import { combineReducers } from '@reduxjs/toolkit';
import { configureDataStore, petConsoleReducers } from '@petconsole/pure-fe-state';

const reducers = petConsoleReducers;

// Create the root reducer separately, so we can extract the RootState type
const rootReducer = combineReducers(reducers);

export const setupTestStore = (preloadedState?: Partial<RootState>) =>
  configureDataStore({ reducers: rootReducer, preloadedState });

export type AppStore = ReturnType<typeof setupTestStore>;
export type RootState = ReturnType<typeof rootReducer>;

export default configureDataStore({ reducers });
