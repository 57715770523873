import * as React from 'react';
import type { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgOurGroomerIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    viewBox="0 0 512 512"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M134.271 153.595c15.947 19.149 44.324 53.134 69.997 83.973 2.584 3.201 5.232 6.276 7.76 9.356 1.598-12.127 3.322-24.746 4.982-37.551.614-4.37 1.172-8.681 1.663-12.868-2.647-2.83-5.23-5.662-7.88-8.494-26.966-29.058-53.559-57.746-74.488-80.151-6.797-7.299-12.943-13.899-18.361-19.632-1.195-1.331-2.392-2.662-3.483-3.905.005.089.018.164.028.253-.074-.075-.155-.164-.224-.235.122 1.289.247 2.522.43 3.756a186.4 186.4 0 0 0 5.973 30.718c1.232 4.495 2.522 8.62 3.88 12.314 4.923 14.16 9.723 22.466 9.723 22.466M429.086 299.56c-15.39-17.734-36.32-27.089-56.081-27.089-13.237 0-25.918 4.186-36.014 12.994-6.21 5.404-10.768 12.084-13.85 19.453-.45 1.008-.944 1.969-1.347 3.013-7.753-8.489-18.44-19.978-30.91-33.487-2.23-2.335-4.495-4.824-6.797-7.303l-1.26-1.547-.014.177-.179-.192c-1.603 19.884-2.648 34.29-2.958 37.486-.06.43-.06.863-.06.863l-.243 2.277c2.522 1.664 5.17 3.76 7.879 6.155 4.987 4.373 10.218 9.792 15.571 15.886 10.163 11.513 18.47 24.439 26.227 37.734 2.587 4.434 5.602 8.68 9.173 12.684 15.39 17.729 36.32 27.084 56.02 27.084 13.3 0 25.978-4.247 36.075-13.05 25.116-21.852 24.563-63.532-1.232-93.138m-72.516 63.097c-7.608-8.7-11.499-19.186-11.715-29.142-.004-.184-.019-.366-.024-.548-.079-9.98 3.568-19.384 10.997-25.834 6.033-5.236 13.603-7.763 21.483-7.763 11.76 0 24.258 5.607 33.43 16.13 7.608 8.71 11.504 19.205 11.715 29.171 0 .17.019.342.019.512.084 9.998-3.563 19.42-10.997 25.908-6.032 5.235-13.603 7.758-21.543 7.758-11.76-.002-24.197-5.603-33.365-16.192" />
    <path d="M255.797 39.366c-.01.08-.022.178-.037.257-.051-.094-.098-.187-.15-.28-4.374 31.208-11.579 85.32-18.9 140.356-1.415 10.585-2.77 21.179-4.185 31.702-2.34 17.916-4.683 35.46-6.774 51.9q-.276 2.214-.554 4.43c-3.66 27.674-6.861 51.73-9.037 68.71q-.397 2.804-.769 5.358c-7.43-5.26-15.961-8.63-25.284-9.615-1.658-.122-3.384-.243-5.047-.243-5.48 0-10.833.924-16.003 2.522-23.949 7.636-42.971 31.95-45.99 62.484-3.938 39.028 19.764 73.382 52.885 76.768 1.66.122 3.385.243 5.043.243 5.48 0 10.838-.924 16.008-2.522 23.949-7.636 42.971-31.951 45.984-62.484.558-5.292.62-10.589.128-15.633-1.294-15.024-1.973-30.046-.497-45.066 2.034-18.96 5.297-34.72 12.13-41.803 1.294-1.415 2.771-2.46 4.37-3.135 1.045-.431 1.045-.431 2.466-.68a13.7 13.7 0 0 1 2.273-.183h.127v-.675c.365-3.876 1.968-24.688 4.124-51.956.123-1.415.244-2.897.305-4.373 3.632-45.558 8.432-106.87 10.898-138.019 0 0-2.1-22.134-14.554-50.076-2.536-5.804-5.48-11.843-8.96-17.987M246.5 276.102c-.432 0-.802 0-1.234-.06-6.276-.614-10.894-6.216-10.218-12.435a11.46 11.46 0 0 1 7.88-9.728 11.9 11.9 0 0 1 3.632-.553h.985c6.22.675 10.832 6.276 10.158 12.558-.432 4.617-3.694 8.371-7.88 9.665a9.9 9.9 0 0 1-3.323.553m-35.016 99.873c5.574 8.245 8.464 19.054 7.312 30.577-1.846 18.16-13.17 32.69-27.393 37.242a30.7 30.7 0 0 1-9.483 1.48q-1.567-.002-3.135-.188c-19.702-1.968-33.863-22.466-31.52-45.735 1.846-18.165 13.172-32.69 27.394-37.247a30.7 30.7 0 0 1 9.478-1.476c1.045 0 2.095.06 3.14.182 9.779.948 18.179 6.492 23.887 14.676.113.16.212.329.32.489" />
  </svg>
);
export default SvgOurGroomerIcon;
